import React, { useEffect } from 'react';
import cn from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import SignIn from 'components/candidate/SignIn';
import Logo from 'components/Logo';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';
import { sendTokenToExtension } from 'utils/common.utils';

import styles from './Signin.module.scss';

const Signin: React.FC<{ utm_source: string }> = ({ utm_source }) => {
  const router = useRouter();
  const {
    isCandidate,
    isTemporaryCandidate,
    isInitialAuthLoading,
    isAuthLoading,
    isNonOnboardedUser,
    user,
  } = useAuth();

  const utmSource = utm_source || 'producthunt';

  const { mode } = router.query;
  const fromProductHunt = utmSource === 'producthunt';

  const onSignInComplete = (isOnboardingCompleted?: boolean) => {
    if (fromProductHunt) {
      return router.push(PAGE_ROUTES.AI_SEARCH);
    } else if (user && mode === 'extension') {
      sendTokenToExtension(user?.email, user?.authentication_token);
    } else if (!isOnboardingCompleted) {
      router.replace(`${PAGE_ROUTES.CANDIDATE_ONBOARDING}`);
    } else {
      redirectUser();
    }
  };

  useEffect(() => {
    //sending token to extension if candidate is logged in
    if (isCandidate && user && mode === 'extension') {
      sendTokenToExtension(user?.email, user?.authentication_token);
    } else if (isCandidate && !isInitialAuthLoading && !isAuthLoading) {
      if (fromProductHunt) {
        router.push(PAGE_ROUTES.AI_SEARCH);
      } else if (isNonOnboardedUser) {
        router.push(`${PAGE_ROUTES.CANDIDATE_ONBOARDING}`);
      } else {
        redirectUser();
      }
    }
  }, [
    isCandidate,
    fromProductHunt,
    isNonOnboardedUser,
    isInitialAuthLoading,
    isAuthLoading,
  ]);

  const redirectUser = () => {
    const { query } = router;
    if (query.redirectUrl) {
      router.push(query.redirectUrl as string);
    } else {
      router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD);
    }
  };

  if (isInitialAuthLoading || isCandidate || isTemporaryCandidate) return null;

  return (
    <>
      <Head>
        <title>Student Sign Up & Login | Huzzle</title>
        <meta
          name="description"
          content="Sign in or sign up to Huzzle as a Student, with just an email."
        />
      </Head>

      <section className={styles.container} data-testid="Signin">
        <div className={styles.gradient1}></div>
        <Logo
          name="huzzle-new"
          size="small"
          color="primary"
          className={cn(styles.logo, styles.hideMobile)}
          onClick={() => router.push(`${process.env.NEXT_PUBLIC_SITE_URL}`)}
        />
        <SignIn
          onComplete={onSignInComplete}
          isLoginPopup={false}
          className={styles.signin}
          utm_source={utmSource}
        />
        <div className={styles.gradient2}></div>
        <div className={styles.gradient3}></div>
      </section>
    </>
  );
};

export async function getServerSideProps({
  locale,
  query,
}: {
  locale: string;
  query: { utm_source?: string };
}) {
  const { utm_source = '' } = query;

  return {
    props: {
      utm_source,
      ...(await serverSideTranslations(locale, 'common')),
    },
  };
}

export default Signin;
